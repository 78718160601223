import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import { commonService } from '../service/store.service.js'

const DashboardLayout = () => import('@/layouts/dashboard.vue')
const PrivateLayout = () => import('@/layouts/private.vue')
const PublicLayout = () => import('@/layouts/public.vue')
const RulesLayout = () => import('@/layouts/rules.vue')

const AnimalActivityChartView = () =>
    import('../components/asset-types/animal/views/AnimalActivityChartView')
const AssetChartsView = () => import('../components/views/AssetChartsView')
const AssetChartsCombinedView = () =>
    import('../components/views/AssetChartsCombinedView')
const AssetDetailView = () => import('@/components/views/AssetDetailView.vue')
const AssetListView = () => import('@/components/views/AssetListView.vue')
const AssetLocationHistory = () =>
    import('../components/views/AssetLocationHistory')
const AssetOperatingStatusChartView = () =>
    import('../components/views/AssetOperatingStatusChartView')
const ChangePasswordView = () =>
    import('../components/views/ChangePasswordView')
const ConnectionHeatmapView = () =>
    import('@/components/views/ConnectionHeatmapView.vue')
const CreateLocationView = () =>
    import('../components/views/CreateLocationView')
const DashboardAssetsView = () =>
    import('../components/views/DashboardAssetsView')
const DashboardOverviewView = () =>
    import('../components/views/DashboardOverviewView')
const MessageStreamView = () => import('../components/views/MessageStreamView')
const DashboardSettingsView = () =>
    import('../components/views/DashboardSettingsView')
const DigitalInputChartView = () =>
    import('../components/views/DigitalInputChartView')
const EditAssetView = () => import('@/components/views/EditAssetView.vue')
const EditLocationView = () => import('../components/views/EditLocationView')
const LocationDetailView = () =>
    import('@/components/views/LocationDetailView.vue')
const NavigationView = () => import('@/components/views/NavigationView.vue')
const NetworkChartView = () => import('../components/views/NetworkChartView')
const RuleEditView = () => import('../components/views/redesigned/RuleEditView')
const RuleListView = () => import('../components/views/redesigned/RuleListView')
const RuleTemplatesView = () =>
    import('../components/views/redesigned/RuleTemplatesView')
const SbbBinChartView = () =>
    import('../components/asset-types/sbb-bin/views/SbbBinChartView')
const SearchView = () => import('@/components/views/SearchView.vue')
const SettingsView = () => import('../components/views/SettingsView')

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/map',
            component: PrivateLayout,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'search',
                    component: SearchView,
                    children: [
                        {
                            path: 'animal-activity-chart',
                            name: 'globalAnimalActivityChart',
                            component: AnimalActivityChartView,
                            beforeEnter: (to, from, next) => {
                                if (
                                    store.getters[
                                        'authentication/hasActivityAccess'
                                    ]
                                ) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                        },
                    ],
                },
                {
                    path: 'navigation',
                    name: 'navigation',
                    component: NavigationView,
                },
                {
                    path: 'connection-heatmap',
                    name: 'connectionHeatmap',
                    component: ConnectionHeatmapView,
                    beforeEnter: (to, from, next) => {
                        if (store.getters['authentication/isSuperuser']) {
                            next()
                        } else {
                            next(false)
                        }
                    },
                },
                {
                    path: 'settings',
                    name: 'settings',
                    component: SettingsView,
                },
                {
                    path: 'change-password',
                    name: 'changePassword',
                    component: ChangePasswordView,
                },
                {
                    path: 'location/create',
                    name: 'createlocation',
                    component: CreateLocationView,
                },
                {
                    path: 'location/:id',
                    name: 'location',
                    component: LocationDetailView,
                    props: true,
                    children: [
                        {
                            path: 'animal-activity-chart',
                            name: 'locationAnimalActivityChart',
                            component: AnimalActivityChartView,
                            props: true,
                            beforeEnter: (to, from, next) => {
                                if (
                                    store.getters[
                                        'authentication/hasActivityAccess'
                                    ]
                                ) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                        },
                    ],
                },
                {
                    path: 'location/:locationId/connection-heatmap',
                    name: 'locationConnectionHeatmap',
                    component: ConnectionHeatmapView,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if (store.getters['authentication/isSuperuser']) {
                            next()
                        } else {
                            next(false)
                        }
                    },
                },
                {
                    path: 'location/:id/edit',
                    name: 'editlocation',
                    component: EditLocationView,
                    props: true,
                },
                {
                    path: 'location/:locationid/:id',
                    name: 'locationDetailAssetDetail',
                    component: AssetDetailView,
                    props: true,
                },
                {
                    path: 'assets',
                    name: 'allAssets',
                    component: AssetListView,
                    props: { type: 'ASSETS_ALL' },
                },

                {
                    path: 'lost-assets',
                    name: 'lostAssets',
                    component: AssetListView,
                    props: { type: 'ASSETS_LOST' },
                },

                {
                    path: 'low-battery-assets',
                    name: 'lowBatteryAssets',
                    component: AssetListView,
                    props: { type: 'ASSETS_LOW_BATTERY' },
                },
                {
                    path: 'assets/:id',
                    alias: ['lost-assets/:id', 'low-battery-assets/:id'],
                    name: 'detail',
                    component: AssetDetailView,
                    props: true,
                    children: [
                        {
                            path: 'operating-status-chart',
                            name: 'operatingstatuschart',
                            component: AssetOperatingStatusChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                        },
                        {
                            path: 'sbb-bin-chart',
                            name: 'sbbbinchart',
                            component: SbbBinChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                        },
                        {
                            path: 'animal-activity-chart',
                            name: 'animalactivitychart',
                            component: AnimalActivityChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                        },
                        {
                            path: 'combined-chart',
                            name: 'chartsCombined',
                            component: AssetChartsCombinedView,
                            beforeEnter: (to, from, next) => {
                                if (to.params.dataTypes?.length) {
                                    next()
                                } else {
                                    next({
                                        name: 'detail',
                                        params: { id: to.params.id },
                                    })
                                }
                            },
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                        },
                        {
                            path: 'network-chart',
                            name: 'networkChart',
                            component: NetworkChartView,
                            props: true,
                            beforeEnter: (to, from, next) => {
                                if (
                                    store.getters['authentication/isSuperuser']
                                ) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                        },
                        {
                            path: 'digital-input-:dataType-chart',
                            name: 'digitalinputchart',
                            component: DigitalInputChartView,
                            props: true,
                        },
                        {
                            path: ':dataType-chart',
                            name: 'charts',
                            component: AssetChartsView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                        },
                    ],
                },
                {
                    path: 'assets/:assetId/connection-heatmap',
                    name: 'assetConnectionHeatmap',
                    component: ConnectionHeatmapView,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if (store.getters['authentication/isSuperuser']) {
                            next()
                        } else {
                            next(false)
                        }
                    },
                },
                {
                    path: 'assets/:id/edit',
                    name: 'editAsset',
                    component: EditAssetView,
                    props: true,
                },
                {
                    path: 'assets/:id/location-history',
                    name: 'locationhistory',
                    component: AssetLocationHistory,
                    props: true,
                },
                {
                    path: 'deveui/:deveui',
                    beforeEnter: (to, from, next) => {
                        commonService.populateData().then(() => {
                            const deveui = to.params.deveui
                            const tracker = store.state.tracker.trackers.find(
                                entry => entry.deveui === deveui
                            )
                            if (tracker) {
                                next(`/map/assets/${tracker.id}`)
                            } else {
                                console.log('No tracker found for given DevEUI')
                                next('/map')
                            }
                        })
                    },
                },
            ],
        },

        {
            path: '/dashboard',
            component: DashboardLayout,
            meta: {
                requiresAuth: true,
            },
            beforeEnter: (to, from, next) =>
                !store.getters['authentication/hasDashboardAccess']
                    ? next(false)
                    : next(),
            children: [
                {
                    path: '',
                    name: 'overview',
                    component: DashboardOverviewView,
                },
                {
                    path: 'assets',
                    name: 'assets',
                    component: DashboardAssetsView,
                },
                {
                    path: 'settings',
                    name: 'dashboardSettings',
                    component: DashboardSettingsView,
                },
            ],
        },

        {
            path: '/message-stream',
            name: 'messageStream',
            component: MessageStreamView,
        },

        {
            path: '/rules',
            component: RulesLayout,
            meta: {
                requiresAuth: true,
            },
            beforeEnter: (to, from, next) =>
                !store.getters['authentication/hasRulesAccess']
                    ? next(false)
                    : next(),
            children: [
                {
                    path: '',
                    name: 'ruleList',
                    component: RuleListView,
                    props: route => ({
                        queryAssets: route.query.assets
                            ?.split(',')
                            .map(i => parseInt(i)),
                        queryLocations: route.query.locations
                            ?.split(',')
                            .map(i => parseInt(i)),
                    }),
                },
                {
                    path: 'templates',
                    name: 'ruleTemplates',
                    component: RuleTemplatesView,
                },
                {
                    path: 'create',
                    name: 'ruleCreate',
                    props: true,
                    component: RuleEditView,
                },
                {
                    path: ':id',
                    name: 'ruleEdit',
                    component: RuleEditView,
                },
            ],
        },

        {
            path: '/login',
            name: 'login',
            props: true,
            component: PublicLayout,
            beforeEnter: (to, from, next) =>
                store.state.authentication.jwt ? next(false) : next(),
        },

        {
            path: '/demo',
            children: [
                {
                    path: '',
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('authentication/login', {
                            username: process.env.VUE_APP_DEMO_USERNAME,
                            password: process.env.VUE_APP_DEMO_PASSWORD,
                        })
                        await store.dispatch('authentication/loadUserInfo')
                        store.commit('demo/setDemoType', 'ax-track')
                        next(process.env.VUE_APP_DEMO_TARGET)
                        store.dispatch('common/loadData')
                    },
                },
                {
                    path: ':demoType',
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('authentication/login', {
                            username: process.env.VUE_APP_DEMO_USERNAME,
                            password: process.env.VUE_APP_DEMO_PASSWORD,
                        })
                        store.commit('demo/setDemoType', to.params.demoType)
                        switch (to.params.demoType) {
                            case 'ax-city':
                                next(process.env.VUE_APP_AX_CITY_TARGET)
                                break
                            case 'ax-sports':
                                store.commit('map/switchClusteringEnabled')
                                next(process.env.VUE_APP_AX_SPORTS_TARGET)
                                break
                            case 'ax-collect':
                                next(process.env.VUE_APP_AX_COLLECT_TARGET)
                                break
                            case 'ax-track':
                                next(process.env.VUE_APP_AX_TRACK_TARGET)
                                break
                            default:
                                store.commit('demo/setDemoType', 'ax-track')
                                next(process.env.VUE_APP_DEMO_TARGET)
                                break
                        }
                        store.dispatch('common/loadData')
                    },
                },
            ],
        },

        // Catch all
        { path: '*', redirect: '/map' },
    ],
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        try {
            await store.dispatch('authentication/refreshAccessTokenIfNecessary')
        } catch {
            const params = to.path ? { nextUrl: { path: to.path } } : null
            next({ name: 'login', params })
            return
        }
    }

    next()
})

export default router
