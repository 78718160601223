// initial state
const state = {
    locations: [],
    activeLocationOnMap: null,
}

// getters
const getters = {
    getActiveLocationOnMap(state) {
        return state.activeLocationOnMap
    },
    getLocationsSortedByName(state) {
        return [...state.locations].sort((a, b) => a.name.localeCompare(b.name))
    },
}

// actions
const actions = {}

// mutations
const mutations = {
    createLocation(state, data) {
        state.locations.push(data)
    },
    updateLocation(state, data) {
        const location = state.locations.find(
            location => location.id === data.id
        )

        Object.keys(data).forEach(key => (location[key] = data[key]))
    },
    setActiveLocationOnMap(s, t) {
        s.activeLocationOnMap = t
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
