// This are global translations. All other translations are made in the components!
export default {
    en: {
        shared: {
            measurements: {
                battery: 'Battery',
                battery_voltage: 'Battery voltage',
                co2: 'CO2',
                distance: 'Distance',
                fill_level: 'Fill level',
                geofence: 'Geofence',
                humidity: 'Humidity',
                lower_loop: 'Lower loop',
                potential: 'Potential',
                state: 'State',
                probe_state: 'Probe State',
                temperature: 'Temperature',
                upper_loop: 'Upper loop',
                voc: 'VOC',
                water_level: 'Water level',
                tau: 'Tau',
                offline: 'Offline',
            },
            types: {
                'air-quality': 'Air quality',
                'compressor-trailer': 'Compressor trailer',
                'default-gasser': 'Various',
                'material-container': 'Material container',
                'parking-space': 'Parking space',
                'sbb-bin': 'SBB recycling station',
                'sbb-bin-alu': 'SBB recycling station aluminium',
                'sbb-bin-paper': 'SBB recycling station paper',
                'sbb-bin-pet': 'SBB recycling station PET',
                'sbb-bin-waste': 'SBB recycling station waste',
                'skip-bin': 'Skip bin',
                'team-container': 'Team container',
                'team-trailer': 'Team trailer',
                'waste-dump': 'Waste dump',
                'wheel-loader': 'Wheel Loader',
                Traktor: 'Tractor',
                Vespa: 'Vespa',
                bicycle: 'Bicycle',
                bin: 'Bin',
                boat: 'Boat',
                bus: 'Bus',
                car: 'Car',
                compressor: 'Compressor',
                dumper: 'Dumper',
                excavator: 'Excavator',
                glasbock: 'Glas pallet',
                horse: 'Horse',
                hund: 'Dog',
                kuh: 'Cow',
                lama: 'Lama',
                roller: 'Roller',
                schaf: 'Sheep',
                scooter: 'Scooter',
                temperature: 'Temperature',
                trailer: 'Trailer',
                truck: 'Truck',
                ziege: 'Goat',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Cancel',
            noSearchResult: 'No search result',
        },
        router: {
            accel_y: 'Collection',
            allAssets: 'All Assets',
            animalactivitychart: 'Activity',
            assetConnectionHeatmap: 'Connection Heatmap',
            battery: 'Battery',
            battery_voltage: 'Battery voltage',
            changePassword: 'Change Password',
            chartsCombined: 'Combined chart',
            charts: 'Charts',
            co2: 'Carbon dioxide',
            connectionHeatmap: 'Connection Heatmap',
            createlocation: 'New Location',
            current: 'Silo',
            d1: 'Channel 1',
            d2: 'Channel 2',
            dashboardSettings: 'Settings',
            detail: 'Details',
            digitalinputchart: 'State',
            distance: 'Distance',
            editAsset: 'Edit Asset',
            editlocation: 'Edit Location',
            fillLevel: 'Fill level',
            fill_level: 'Fill level',
            globalAnimalActivityChart: 'Activity',
            humidity: 'Humidity',
            location: 'Location',
            locationAnimalActivityChart: 'Activity',
            locationConnectionHeatmap: 'Connection Heatmap',
            locationDetailAssetDetail: 'Details',
            locationhistory: 'Location history',
            lostAssets: 'Left zone',
            lowBatteryAssets: 'Low battery',
            lower_loop: 'Lower loop',
            mass: 'Mass',
            menu: 'Menu',
            navigation: 'Navigation',
            networkChart: 'Signal Quality',
            newAsset: 'New Tracker',
            notifications: 'Notifications',
            operatingstatuschart: 'Operating Status',
            potential: 'Potential',
            sbbbinchart: 'Fill level',
            search: 'Search',
            settings: 'Settings',
            tau: 'Tau',
            temperature: 'Temperature',
            tracking: 'Tracking',
            upper_loop: 'Upper loop',
            voc: 'Volatile organic compounds',
            volume: 'Volume',
            water_level: 'Water level',
        },
    },
    de: {
        shared: {
            measurements: {
                battery: 'Batterie',
                battery_voltage: 'Batteriespannung',
                co2: 'CO2',
                distance: 'Distanz',
                fill_level: 'Füllstand',
                geofence: 'Geofence',
                humidity: 'Luftfeuchtigkeit',
                lower_loop: 'Untere Schlaufe',
                potential: 'Potential',
                state: 'Zustand',
                probe_state: 'Sondenstatus',
                temperature: 'Temperatur',
                upper_loop: 'Obere Schlaufe',
                voc: 'VOC',
                water_level: 'Wasserpegel',
                tau: 'Tau',
                offline: 'Offline',
            },
            types: {
                'air-quality': 'Luftqualität',
                'compressor-trailer': 'Kompressoranhänger',
                'default-gasser': 'Diverses',
                'material-container': 'Materialcontainer',
                'parking-space': 'Parkplatz',
                'sbb-bin': 'SBB Recycling Station',
                'sbb-bin-alu': 'SBB Recyclingstation Alu',
                'sbb-bin-paper': 'SBB Recyclingstation Papier',
                'sbb-bin-pet': 'SBB Recyclingstation PET',
                'sbb-bin-waste': 'SBB Recyclingstation Abfall',
                'skip-bin': 'Abfallmulde',
                'team-container': 'Mannschaftscontainer',
                'team-trailer': 'Mannschaftswagen',
                'waste-dump': 'Mulde',
                'wheel-loader': 'Radlader',
                Traktor: 'Traktor',
                Vespa: 'Vespa',
                bicycle: 'Fahrrad',
                bin: 'Abfalleimer',
                boat: 'Boot',
                bus: 'Bus',
                car: 'Auto',
                compressor: 'Kompressor',
                dumper: 'Dumper',
                excavator: 'Bagger',
                glasbock: 'Glasbock',
                horse: 'Pferd',
                hund: 'Hund',
                kuh: 'Kuh',
                lama: 'Lama',
                roller: 'Walze',
                schaf: 'Schaf',
                scooter: 'Scooter',
                temperature: 'Temperatur',
                trailer: 'Anhänger',
                truck: 'Lastwagen',
                ziege: 'Ziege',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Abbrechen',
            noSearchResult: 'Kein Suchresultat',
        },
        router: {
            accel_y: 'Leerung',
            allAssets: 'Alle Assets',
            animalactivitychart: 'Aktivität',
            assetConnectionHeatmap: 'Verbindungsqualität',
            battery: 'Batterie',
            battery_voltage: 'Batteriespannung',
            changePassword: 'Passwort ändern',
            chartsCombined: 'Kombinierter Graph',
            charts: 'Diagramme',
            co2: 'Kohlenstoffdioxid',
            connectionHeatmap: 'Verbindungsqualität',
            createlocation: 'Neuer Standort',
            current: 'Silo',
            d1: 'Kanal 1',
            d2: 'Kanal 2',
            dashboardSettings: 'Einstellungen',
            detail: 'Details',
            digitalinputchart: 'Zustand',
            distance: 'Distanz',
            editAsset: 'Asset bearbeiten',
            editlocation: 'Standort bearbeiten',
            fillLevel: 'Füllstand',
            fill_level: 'Füllstand',
            globalAnimalActivityChart: 'Aktivität',
            humidity: 'Luftfeuchtigkeit',
            location: 'Standort',
            locationAnimalActivityChart: 'Aktivität',
            locationConnectionHeatmap: 'Verbindungsqualität',
            locationDetailAssetDetail: 'Details',
            locationhistory: 'Verlauf',
            lostAssets: 'Zone verlassen',
            lowBatteryAssets: 'Niedriger Batteriestand',
            lower_loop: 'Untere Schlaufe',
            mass: 'Masse',
            menu: 'Menü',
            navigation: 'Navigation',
            networkChart: 'Signal Qualität',
            newAsset: 'Neuer Tracker',
            notifications: 'Benachrichtigungen',
            operatingstatuschart: 'Betriebszustand',
            potential: 'Potential',
            sbbbinchart: 'Füllstand',
            search: 'Suche',
            settings: 'Einstellungen',
            tau: 'Tau',
            temperature: 'Temperatur',
            tracking: 'Tracking',
            upper_loop: 'Obere Schlaufe',
            voc: 'Flüchtige organische Verbindungen',
            volume: 'Volumen',
            water_level: 'Wasserpegel',
        },
    },
    it: {
        shared: {
            measurements: {
                battery: 'Batteria',
                battery_voltage: 'Tensione della batteria',
                co2: 'CO2',
                distance: 'Distanza',
                fill_level: 'Livello di riempimento',
                geofence: 'Geofence',
                humidity: 'Umidità',
                lower_loop: 'Lower loop',
                potential: 'Potential',
                state: 'State',
                probe_state: 'Stato campione',
                temperature: 'Temperatura',
                upper_loop: 'Upper loop',
                voc: 'VOC',
                water_level: "Livello dell'acqua",
                tau: 'Tau',
                offline: 'Offline',
            },
            types: {
                'air-quality': 'Air quality',
                'compressor-trailer': 'Rimorchio a compressore',
                'default-gasser': 'Varie',
                'material-container': 'Contenitore di materiale',
                'parking-space': 'Parking space',
                'sbb-bin': 'SBB recycling station',
                'sbb-bin-alu':
                    "Stazione di riciclaggio dell'alluminio delle FFS",
                'sbb-bin-paper': 'Carta della stazione di riciclaggio FFS',
                'sbb-bin-pet': 'Stazione di riciclaggio FFS PET',
                'sbb-bin-waste': 'Rifiuti delle stazioni di riciclaggio FFS',
                'skip-bin': 'Skip bin',
                'team-container': 'Team-contenitore',
                'team-trailer': 'Team trailer',
                'waste-dump': 'Discarica',
                'wheel-loader': 'Caricatore a ruota',
                Traktor: 'Trattore',
                Vespa: 'Vespa',
                bicycle: 'Bicycle',
                bin: 'Bin',
                boat: 'Boat',
                bus: 'Bus',
                car: 'Auto',
                compressor: 'Compressore',
                dumper: 'Dumper',
                excavator: 'Excavator',
                glasbock: 'Glas pallet',
                horse: 'Horse',
                hund: 'Dog',
                kuh: 'Cow',
                lama: 'Lama',
                roller: 'Rullo',
                schaf: 'Sheep',
                scooter: 'Scooter',
                temperature: 'Temperature',
                trailer: 'Trailer',
                truck: 'Truck',
                ziege: 'Goat',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Staccare',
            noSearchResult: 'Nessun risultato di ricerca',
        },
        router: {
            accel_y: 'Collezione',
            allAssets: 'Tutti Assets',
            animalactivitychart: 'Attività',
            assetConnectionHeatmap: 'Qualità della connessione',
            battery: 'Batteria',
            battery_voltage: 'Tensione della batteria',
            changePassword: 'Modificare Password',
            chartsCombined: 'Grafico combinato',
            charts: 'Grafici',
            co2: 'Anidride carbonica',
            connectionHeatmap: 'Qualità della connessione',
            createlocation: 'Nuovo Locazione',
            current: 'Silo',
            d1: 'Channel 1',
            d2: 'Channel 2',
            dashboardSettings: 'Impostazione',
            detail: 'Dettaglio',
            digitalinputchart: 'State',
            distance: 'Distanza',
            editAsset: 'Modificare Asset',
            editlocation: 'Modificare Locazione',
            fillLevel: 'Livello di riempimento',
            fill_level: 'Livello di riempimento',
            globalAnimalActivityChart: 'Attività',
            humidity: 'Umidità',
            location: 'Locazione',
            locationAnimalActivityChart: 'Attività',
            locationConnectionHeatmap: 'Qualità della connessione',
            locationDetailAssetDetail: 'Dettaglio',
            locationhistory: 'Storico',
            lostAssets: 'Lascia la zona',
            lowBatteryAssets: 'Basso Batteria',
            lower_loop: 'Lower loop',
            mass: 'Massa',
            menu: 'Menu',
            navigation: 'Navigation',
            networkChart: 'Qualità del segnale',
            newAsset: 'Nuovo Asset',
            notifications: 'Notificazione',
            operatingstatuschart: 'Stato di funzionamento',
            potential: 'Potential',
            sbbbinchart: 'Livello di riempimento',
            search: 'Ricerca',
            settings: 'Impostazione',
            tau: 'Tau',
            temperature: 'Temperatura',
            tracking: 'Tracking',
            upper_loop: 'Upper loop',
            voc: 'Composti organici volatili',
            volume: 'Volume',
            water_level: "Livello dell'acqua",
        },
    },
}
