<template>
    <div class="app-toolbar">
        <transition name="fade">
            <div v-if="userInfo" class="l-inline l-center-v">
                <router-link to="/map" class="app-toolbar__link">
                    {{ $t('map') }}
                </router-link>

                <router-link
                    v-if="hasDashboardAccess"
                    to="/dashboard"
                    class="app-toolbar__link"
                >
                    {{ $t('link2') }}
                </router-link>

                <router-link
                    v-if="hasRulesAccess"
                    to="/rules"
                    class="app-toolbar__link"
                >
                    {{ $t('link3') }}
                </router-link>

                <div
                    class="app-toolbar__menu-icon"
                    @click="showMenu = !showMenu"
                >
                    <transition name="squash-swap">
                        <remove-icon v-if="showMenu" width="24" height="24" />

                        <menu-icon v-else width="24" height="24" />
                    </transition>
                </div>
            </div>
        </transition>

        <transition name="slide-from-bottom">
            <div
                v-if="showMenu"
                class="app-toolbar__menu"
                @click="showMenu = false"
            >
                <router-link to="/map">
                    {{ $t('map') }}
                </router-link>

                <router-link v-if="hasDashboardAccess" to="/dashboard">
                    {{ $t('link2') }}
                </router-link>

                <router-link v-if="hasRulesAccess" to="/rules">
                    {{ $t('link3') }}
                </router-link>

                <router-link
                    v-if="isNavigationAvailable"
                    :to="{ name: 'navigation' }"
                >
                    {{ $t('navigation') }}
                </router-link>

                <router-link
                    v-if="isSuperuser"
                    :to="{ name: 'connectionHeatmap' }"
                >
                    {{ $t('connectionHeatmap') }}
                </router-link>

                <router-link :to="{ name: 'settings' }">
                    {{ $t('settings') }}
                </router-link>

                <router-link :to="{ name: 'changePassword' }">
                    {{ $t('changePassword') }}
                </router-link>

                <a @click="showUpdatesModal">
                    {{ $t('changelog') }}
                </a>

                <a @click="handleLogout">
                    {{ $t('logout') }}
                </a>

                <a v-if="canInstall" @click="install">
                    {{ $t('install') }}
                </a>
            </div>
        </transition>

        <whitelabled-logo
            v-if="!isWhitelabled"
            :height="20"
            class="app-toolbar__logo"
        />

        <transition name="fade">
            <div v-if="userInfo">
                <transition name="fade">
                    <div
                        v-if="isMapViewActive && !showMenu"
                        class="app-toolbar__map-controls"
                    >
                        <map-controls mobile />
                    </div>
                </transition>

                <a class="app-toolbar__link" @click="openDropdownPanel">
                    {{ userName }}

                    <arrow-down-icon
                        :width="14"
                        :height="14"
                        class="app-toolbar__dropdown-icon"
                        :class="{
                            'app-toolbar__dropdown-icon--active': showDropdownPanel,
                        }"
                    />
                </a>

                <transition name="dropdown">
                    <div v-if="showDropdownPanel" class="app-toolbar__dropdown">
                        <router-link
                            v-if="isNavigationAvailable"
                            :to="{ name: 'navigation' }"
                        >
                            <navigation-icon width="16" height="16" />

                            {{ $t('navigation') }}
                        </router-link>

                        <router-link
                            v-if="isSuperuser"
                            :to="{ name: 'connectionHeatmap' }"
                        >
                            <heatmap-icon width="16" height="16" />

                            {{ $t('connectionHeatmap') }}
                        </router-link>

                        <router-link :to="{ name: 'settings' }">
                            <settings-slider-icon width="16" height="16" />

                            {{ $t('settings') }}
                        </router-link>

                        <router-link :to="{ name: 'changePassword' }">
                            <change-password-icon width="16" height="16" />

                            {{ $t('changePassword') }}
                        </router-link>

                        <a @click="showUpdatesModal">
                            <info-icon width="16" height="16" />

                            {{ $t('changelog') }}
                        </a>

                        <a @click="handleLogout">
                            <logout-icon width="16" height="16" />

                            {{ $t('logout') }}
                        </a>

                        <a v-if="canInstall" @click="install">
                            <installation-icon width="16" height="16" />

                            {{ $t('install') }}
                        </a>
                    </div>
                </transition>

                <v-dialog />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { pwaHelper } from '@/utils'

import ArrowDownIcon from '../icons/ArrowDownIcon.vue'
import ChangePasswordIcon from '../icons/ChangePasswordIcon'
import HeatmapIcon from '../icons/HeatmapIcon'
import InfoIcon from '../icons/InfoIcon.vue'
import InstallationIcon from '../icons/InstallationIcon'
import LogoutIcon from '../icons/LogoutIcon.vue'
import MapControls from '../redesigned/MapControls'
import MenuIcon from '../icons/MenuIcon.vue'
import NavigationIcon from '../icons/NavigationIcon'
import RemoveIcon from '../icons/RemoveIcon.vue'
import SettingsSliderIcon from '../icons/SettingsSliderIcon'
import WhitelabledLogo from '../WhitelabledLogo'
import Whitelabling from '../../mixins/Whitelabling'

export default {
    name: 'AppToolbar',
    components: {
        ArrowDownIcon,
        ChangePasswordIcon,
        HeatmapIcon,
        InfoIcon,
        InstallationIcon,
        LogoutIcon,
        MapControls,
        MenuIcon,
        NavigationIcon,
        RemoveIcon,
        SettingsSliderIcon,
        WhitelabledLogo,
    },
    mixins: [Whitelabling],
    data() {
        return {
            canInstall: false,
            showDropdownPanel: false,
            showMenu: false,
        }
    },
    computed: {
        ...mapState('authentication', ['userInfo']),
        ...mapGetters('authentication', [
            'hasDashboardAccess',
            'hasRulesAccess',
            'isNavigationAvailable',
            'isSuperuser',
        ]),
        isMapViewActive() {
            return this.$route.path.match(/^\/map/i)
        },
        userName() {
            const { first_name, last_name, username } = this.userInfo
            return `${first_name} ${last_name}`.trim() || username
        },
    },
    async mounted() {
        this.canInstall = await pwaHelper.canInstall()
    },
    methods: {
        ...mapActions('authentication', ['logout']),
        handleLogout() {
            if (this.$socket?.connected) {
                this.$socket.client.disconnect()
            }

            this.logout()
            this.$router.push('/login', () => {
                this.$router.go()
            })
        },
        openDropdownPanel() {
            if (this.showDropdownPanel) {
                return
            }

            this.showDropdownPanel = true

            const listener = () => {
                document.body.removeEventListener('click', listener)
                this.showDropdownPanel = false
            }

            setTimeout(() => {
                document.body.addEventListener('click', listener)
            })
        },
        showUpdatesModal() {
            pwaHelper.showChangelog()
        },
        install() {
            pwaHelper.install()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "changePassword": "Change password",
        "changelog": "Updates",
        "close": "Close",
        "connectionHeatmap": "Connection heatmap",
        "install": "Install",
        "map": "Map",
        "link2": "Management",
        "link3": "Rules",
        "logout": "Logout",
        "navigation": "Navigation",
        "settings": "Settings"
    },
    "de": {
        "changePassword": "Passwort ändern",
        "changelog": "Updates",
        "close": "Schliessen",
        "connectionHeatmap": "Verbindungsqualität",
        "install": "Installieren",
        "map": "Karte",
        "link2": "Verwaltung",
        "link3": "Regeln",
        "logout": "Logout",
        "navigation": "Navigation",
        "settings": "Einstellungen"
    },
    "it": {
        "changePassword": "Modificare Password",
        "changelog": "Aggiornamenti",
        "close": "Chiudere",
        "connectionHeatmap": "Qualità della connessione",
        "install": "Installare",
        "map": "Mappa",
        "link2": "Management",
        "link3": "Regole",
        "logout": "Logout",
        "navigation": "Navigazione",
        "settings": "Impostazione"
    }
}
</i18n>

<style lang="scss" scoped>
.app-toolbar {
    flex: 0 0 64px;
    width: 100%;
    height: 64px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    background-color: $color-primary;
    user-select: none;

    & > * {
        position: relative;
        width: 50%;

        &:last-child {
            text-align: right;
        }
    }

    &__logo {
        display: block;
        width: 10%;
        min-width: 100px;
        margin: 0 auto;
    }

    &__link {
        position: relative;
        display: inline-block;
        padding: 0 1rem;
        color: rgba($color-primary-contrast, 0.7);
        line-height: 64px;
        font-weight: 600;
        font-size: 16px;
        transition: all 0.1s;
        cursor: pointer;

        &:hover {
            color: $color-primary-contrast;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -6px;
            border: 6px solid transparent;
            border-bottom-color: $color-accent;
            opacity: 0;
            transition: all 200ms;
        }

        &.router-link-active {
            color: $color-primary-contrast;

            &::after {
                opacity: 1;
            }
        }
    }

    &__dropdown-icon {
        margin-left: 2px;
        transition: all 0.2s;

        &--active {
            margin-bottom: -1px;
            transform: rotateZ(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 6px 8px 12px;
        padding: 8px 0;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-size: 14px;
        text-align: left;
        z-index: 1000;

        a {
            display: block;
            padding: 12px 20px;
            transition: background-color 0.1s;
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter-new;
            }

            svg {
                margin-right: 8px;
                margin-bottom: -3px;
            }
        }
    }

    &__menu-icon {
        display: none;
        color: $color-primary-contrast;
    }

    &__menu {
        position: fixed;
        top: 64px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100vw;
        height: 100vh;
        background-color: $color-gray-lighter-new;
        z-index: 10;

        a {
            margin-top: -64px;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.3);
        }
    }

    &__map-controls {
        display: none;
    }

    @include respond-to('for-mobile-down') {
        flex-basis: 56px;
        height: 56px;

        &__link {
            display: none;
        }

        &__menu-icon {
            display: block;
        }

        &__menu {
            top: 56px;
        }

        &__map-controls {
            display: block;
        }
    }
}

.dropdown {
    &-enter-active,
    &-leave-active {
        transition: all 0.1s ease-out;
    }

    &-enter,
    &-leave-to {
        transform: translateY(-8px);
        opacity: 0;
    }
}

.fade {
    &-enter-active,
    &-leave-active {
        transition: all 0.2s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}

.slide-from-bottom {
    &-enter-active,
    &-leave-active {
        transition: all 0.2s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateY(1rem);
    }
}

.squash-swap {
    &-enter-active,
    &-leave-active {
        transition: all 0.2s;
    }

    &-enter,
    &-leave-to {
        position: absolute;
        transform: scaleY(0.1);
        opacity: 0;
    }
}
</style>
