import { commonService } from '@/service/store.service'

const state = {
    isDataLoaded: false,
    isDataLoading: false,
}

const getters = {}

const actions = {
    async loadData({ commit, rootState }) {
        if (!rootState.authentication.jwt) {
            return
        }

        commit('setDataLoading', true)
        await commonService.populateData()
        commit('setDataLoading', false)
        commit('setDataLoaded', true)
    },
}

const mutations = {
    setDataLoaded(state, data) {
        state.isDataLoaded = data
    },
    setDataLoading(state, data) {
        state.isDataLoading = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
