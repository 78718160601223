<template>
    <img
        v-if="isWhitelabled"
        :src="whitelabelLogo"
        alt="Logo"
        :height="height"
    />
    <logo-icon v-else light :height="height" :width="4 * height" />
</template>

<script>
import LogoIcon from '@/components/icons/LogoIcon'
import Whitelabling from '@/mixins/Whitelabling'

export default {
    name: 'Logo',
    components: {
        LogoIcon,
    },
    mixins: [Whitelabling],
    props: {
        height: {
            type: [Number, String],
            default: 40,
        },
    },
}
</script>

<style scoped lang="scss">
img {
    width: auto;
}
</style>
