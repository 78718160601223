import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './modules/authentication'
import common from './modules/common'
import config from './modules/config'
import createLocation from './modules/createLocation'
import demo from './modules/demo'
import locations from './modules/locations'
import map from './modules/map'
import tracker from './modules/tracker'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        authentication,
        common,
        config,
        createLocation,
        demo,
        locations,
        map,
        tracker,
        user,
    },
})
