<template>
    <button
        v-if="!noButton"
        class="icon-button"
        :class="{
            'icon-button__filled': type == 'filled',
            'icon-button__outlined': type == 'outlined',
        }"
        @click="$emit('click')"
    >
        <div class="icon-button__hover" :style="hoverElementStyle"></div>
        <div class="icon-button__content">
            <slot />
        </div>
    </button>

    <div
        v-else
        class="icon-button"
        :class="{
            'icon-button__filled': type == 'filled',
            'icon-button__outlined': type == 'outlined',
        }"
        @click="$emit('click')"
    >
        <div class="icon-button__hover" :style="hoverElementStyle"></div>
        <div class="icon-button__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'IconButton',
    components: {},
    props: {
        type: {
            type: String,
            default: null,
        },
        noButton: {
            type: Boolean,
            default: false,
        },
        hoverColor: {
            type: String,
            default: '',
        },
    },
    computed: {
        hoverElementStyle() {
            if (this.hoverColor.length) {
                return { 'background-color': this.hoverColor }
            }
            return {}
        },
    },
}
</script>

<style scoped lang="scss">
.icon-button {
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    margin-left: 5px;
    user-select: none;
    position: relative;
    outline: none;
}

.icon-button__content {
    position: relative;
}

.icon-button:active {
    color: initial;
}

.icon-button__hover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(-20%, -20%, 0) scale(0);
    width: 175%;
    height: 150%;
    background: $color-gray-light;
    border-radius: 50%;
    transition: all 0.15s;
}

.icon-button:hover .icon-button__hover {
    opacity: 1;
    transform: translate3d(-20%, -20%, 0) scale(1);
}

.icon-button__filled {
    color: $color-gray-dark;
    border: 2px solid $color-gray-medium;
    border-radius: $br-input;
    padding: 0.125rem;
    font-size: 20px;
}

.icon-button__filled:hover {
    background: black;
    border: 2px solid black;
    color: white;
}

.icon-button__filled .icon-button__hover {
    display: none;
}

.icon-button__outlined {
    border: 1.5px solid black;
    border-radius: $br-input;
    padding: 0.125rem;
}

.icon-button__outlined:hover {
    transition: background 0.25s;
    background: $color-gray-medium;
}

.icon-button__outlined .icon-button__hover {
    display: none;
}
</style>
