<template>
    <div id="app">
        <offline-warning v-if="isOffline" />

        <loading-overlay v-if="isDataLoading" />

        <div v-else class="base-layout l-stack">
            <simulation-mode-bar v-if="isSimulationMode" />

            <app-toolbar v-if="isToolbarVisible" />

            <router-view />
        </div>

        <notifications position="bottom right" />

        <notifications position="bottom center" group="center" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppToolbar from './components/redesigned/AppToolbar'
import LoadingOverlay from './components/redesigned/LoadingOverlay'
import OfflineWarning from './components/OfflineWarning'
import SimulationModeBar from './components/SimulationModeBar'
import SocketHelper from './mixins/SocketHelper'
import TranslationHelper from './mixins/TranslationHelper'

export default {
    name: 'App',
    components: {
        AppToolbar,
        LoadingOverlay,
        OfflineWarning,
        SimulationModeBar,
    },
    mixins: [SocketHelper, TranslationHelper],
    computed: {
        ...mapState('authentication', ['isSimulationMode']),
        ...mapState('common', ['isDataLoading']),
        isToolbarVisible() {
            return (
                this.$route.name !== 'login' &&
                this.$route.name !== 'messageStream'
            )
        },
    },
    async mounted() {
        await this.loadData()
    },
    methods: {
        ...mapActions('common', ['loadData']),
    },
}
</script>

<style lang="scss">
@import './styles/base.scss';
@import './styles/context-menu.scss';
@import './styles/datepicker.scss';
@import './styles/detachedpanel.scss';
@import './styles/forms.scss';
@import './styles/layout.scss';
@import './styles/marker.scss';
@import './styles/modal.scss';
@import './styles/multiselect.scss';
@import './styles/notifications.scss';
@import './styles/radio.scss';
@import './styles/redesigned.scss';
@import './styles/timepicker.scss';
@import './styles/utilities.scss';

#app {
    color: $color-text;
    font-family: 'IBM Plex Sans';

    & > .base-layout {
        width: 100vw;
        height: 100vh;
    }
}
</style>
