<template>
    <div>
        <div v-if="!isHidden" class="wrapper" @click="close">
            <NetworkWarningIcon color="#fff" width="64" height="64" />

            <h1>{{ $t('title') }}</h1>

            <p>{{ $t('description') }}</p>

            <p class="close-tip">{{ $t('tip') }}</p>
        </div>
    </div>
</template>

<script>
import NetworkWarningIcon from './icons/NetworkWarningIcon'

export default {
    name: 'OfflineWarning',
    components: {
        NetworkWarningIcon,
    },
    data() {
        return {
            isHidden: false,
        }
    },
    methods: {
        close() {
            this.isHidden = true
        },
    },
}
</script>

<i18n>
{
    "en": {
        "title": "You are offline now",
        "description": "You can still use the application, but there will be no interaction with the server.",
        "tip": "Click anywhere to continue"
    },
    "de": {
        "title": "You are offline now",
        "description": "You can still use the application, but there will be no interaction with the server.",
        "tip": "Click anywhere to continue"
    },
    "it": {
        "title": "You are offline now",
        "description": "You can still use the application, but there will be no interaction with the server.",
        "tip": "Click anywhere to continue"
    }
}
</i18n>

<style lang="scss" scoped>
.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    user-select: none;
    z-index: 10;

    & > * {
        max-width: 320px;
        margin-bottom: 24px;
        text-align: center;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .close-tip {
        position: fixed;
        bottom: 0;
    }
}
</style>
