const logEvent = (message, data) => {
    if (window.location.href.includes('debug')) {
        console.log(message, data)
    }
}

export default {
    sockets: {
        connect() {
            const token = this.$store.state.authentication.jwt?.access

            if (token) {
                this.$store
                    .dispatch('authentication/refreshAccessTokenIfNecessary')
                    .then(() => {
                        this.$socket.client.emit('authenticate', { token })
                    })
            }
        },
        debug(data) {
            logEvent('Socket debug message:', data)
        },
        keepalive(data) {
            logEvent('Received keepalive update', data)
            this.$store.commit('tracker/updateTrackerLastContact', {
                id: data.tracker,
                time: data.timestamp,
                alert: data.alert,
            })
        },
        position(data) {
            logEvent('Received position update', data)
            this.$store.commit('tracker/updateTrackerPosition', {
                id: data.tracker,
                lat: data.lat,
                lng: data.long,
                isOutside: data.is_outside,
                time: data.timestamp,
            })
        },
        sensor_data(data) {
            logEvent('Received sensor data update', data)
            this.$store.commit('tracker/updateTrackerSensorData', {
                id: data.tracker,
                values: data.values,
                time: data.timestamp,
            })
        },
    },
    watch: {
        ['$store.state.authentication.jwt'](jwt) {
            if (jwt) {
                this.$socket.client.emit('authenticate', {
                    token: jwt.access,
                })
            }
        },
    },
}
