import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'
import VueTruncate from 'vue-truncate-filter'
import Notifications from 'vue-notification'
import VueOffline from 'vue-offline'
import VueSocketIO from 'vue-socket.io-extended'
import io from 'socket.io-client'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import store from './store'
import router from './router'
import { messages } from './i18n'
import { languageHelper } from './utils'
import App from './App.vue'

import './registerServiceWorker'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

// Adding Senetry for Error logging
if (
    process.env.NODE_ENV !== 'development' &&
    process.env.VUE_APP_ENVIRONMENT_NAME !== 'feature review'
) {
    Sentry.init({
        dsn: 'https://e6967b80198947e5a9db78fd651c4479@sentry.io/1481557',
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true,
            }),
        ],
        release: 'ax-track@' + process.env.VUE_APP_VERSION,
        environment: process.env.VUE_APP_ENVIRONMENT_NAME,
    })
    if (
        store.getters['authentication/getJWT'] &&
        store.getters['authentication/getJWT'].access
    ) {
        let currentUserId = store.getters['authentication/getCurrentUserId']
        Sentry.configureScope(scope => {
            scope.setUser({ id: currentUserId })
        })
    }
}

// Add Vue plugins
Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(VModal, {
    dialog: true,
    dynamic: true,
    injectModalsContainer: true,
})
Vue.use(Notifications)
Vue.use(VueTruncate)
Vue.use(VueOffline)
Vue.use(VueSocketIO, io(process.env.VUE_APP_WEBSOCKET_URL))

Vue.directive('focus', {
    inserted: function(el) {
        el.focus()
    },
})

// Initialize internationalization
const i18n = new VueI18n({
    locale: languageHelper.getUsersLanguage(), // set locale
    silentFallbackWarn: true,
    messages, // set locale messages
})

// Create Vue instance
new Vue({
    el: '#app',
    router,
    store,
    VueAxios,
    axios,
    i18n,
    render: h => h(App),
})
