export default {
    computed: {
        isWhitelabled() {
            return (
                !!process.env.VUE_APP_LOGO_ASSET_PATH &&
                process.env.VUE_APP_LOGO_ASSET_PATH !== 'logo.svg'
            )
        },
        whitelabelLogo() {
            return require(`@/assets/${process.env.VUE_APP_LOGO_ASSET_PATH}`)
        },
    },
}
