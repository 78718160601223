// initial state
const state = {
    isDemo: false,
    demoType: 'ax-track',
}

// getters
const getters = {
    demoType(state) {
        return state.demoType
    },
}

// actions
const actions = {}

// mutations
const mutations = {
    setDemoType(state, data) {
        state.demoType = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
