<template>
    <transition name="fade">
        <div class="loading-overlay">
            <logo-frame-icon
                class="loading-overlay__logo-frame"
                color="#13365e"
                fill-color="#e7ebef"
            />

            <div class="loading-overlay__pattern topography-background"></div>

            <div class="loading-overlay__time">
                <span>{{ time }}</span>
            </div>

            <div class="loading-overlay__logo">
                <template v-if="isWhitelabled">
                    <whitelabled-logo width="360" height="90" />
                </template>

                <template v-else>
                    <logo-icon width="360" height="90" animated />
                </template>

                <div>
                    {{ $t('hint') }}
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import moment from 'moment'

import LogoFrameIcon from '../icons/LogoFrameIcon'
import LogoIcon from '../icons/LogoIcon'
import WhitelabledLogo from '@/components/WhitelabledLogo'
import Whitelabling from '@/mixins/Whitelabling'

export default {
    name: 'LoadingOverlay',
    components: {
        LogoFrameIcon,
        LogoIcon,
        WhitelabledLogo,
    },
    mixins: [Whitelabling],
    data() {
        return {
            time: '',
        }
    },
    mounted() {
        moment.locale(this.$i18n.locale)
        this.time = moment()
            .format('LLLL')
            .replace(/\d{4}/, '$&\n')
    },
}
</script>

<i18n>
{
    "en": {
        "hint": "Devices are loaded ..."
    },
    "de": {
        "hint": "Geräte werden geladen ..."
    },
    "it": {
        "hint": "I dispositivi sono caricati ..."
    }
}
</i18n>

<style lang="scss" scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-gray-light-new;
    font-family: 'IBM Plex Mono';
    font-size: 18px;
    letter-spacing: -0.05em;
    color: rgba($color-primary, 0.5);

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }

    &__logo-frame {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.2;
    }

    &__time {
        position: absolute;
        top: 48px;
        right: 48px;
        text-align: right;

        span {
            white-space: pre-line;
        }
    }

    &__pattern {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include respond-to('for-mobile-down') {
        &__time {
            top: 16px;
            right: 16px;
        }
    }
}

.fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s ease-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
