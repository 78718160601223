export default {
    en: {
        shared: {
            measurements: {
                geofence: 'Location',
            },
            asset: 'Animal',
            assetCount: '{n} Animal | {n} Animals',
            assets: 'Animals',
        },
    },
    de: {
        shared: {
            measurements: {
                geofence: 'Standort',
            },
            asset: 'Tier',
            assetCount: '{n} Tier | {n} Tiere',
            assets: 'Tiere',
        },
    },
    it: {
        shared: {
            measurements: {
                geofence: 'Location',
            },
            asset: 'Animale',
            assetCount: '{n} Animale | {n} Animali',
            assets: 'Animali',
        },
    },
}
