<template>
    <Multiselect
        v-model="valueInternal"
        :options="options"
        track-by="id"
        label="username"
        :placeholder="shouldShowPlaceholder ? '' : placeholder"
        :multiple="multiple"
        :allow-empty="multiple"
        :max="options.length || null"
        :close-on-select="!multiple"
        :hide-selected="multiple"
        :show-labels="false"
        :internal-search="!showDetails"
        @search-change="showDetails && handleSearchChange($event)"
    >
        <template
            v-if="showDetails"
            #singleLabel="{ option: { username, first_name, last_name, account_details: { name: account_name } } }"
        >
            <div class="option">
                <span>
                    <strong>{{ username }}</strong>

                    {{ first_name }} {{ last_name }}
                </span>

                <span>{{ account_name }}</span>
            </div>
        </template>

        <template
            v-if="showDetails"
            #option="{ option: { username, first_name, last_name, account_details: { name: account_name } } }"
        >
            <div class="option">
                <span>
                    <strong>{{ username }}</strong>

                    {{ first_name }} {{ last_name }}
                </span>

                <span>{{ account_name }}</span>
            </div>
        </template>

        <template #noOptions>
            {{ $t('noOptions') }}
        </template>

        <template #noResult>
            {{ $t('noResult') }}
        </template>

        <template #maxElements>
            {{ $t('noOptions') }}
        </template>
    </Multiselect>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
    name: 'UserSelect',
    components: {
        Multiselect,
    },
    props: {
        value: {
            type: [Number, Array],
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        exclude: {
            type: Array,
            default: () => [],
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
        }
    },
    computed: {
        ...mapGetters('authentication', ['isAdminOrStaff']),
        ...mapGetters('user', ['usersSortedByUsername']),
        shouldShowPlaceholder() {
            return this.multiple
                ? this.valueInternal && this.valueInternal.length
                : !!this.valueInternal
        },
        valueInternal: {
            get() {
                if (!this.value) {
                    return null
                }

                return this.multiple
                    ? this.options.filter(option =>
                          this.value.includes(option.id)
                      )
                    : this.options.find(option => this.value === option.id)
            },
            set(value) {
                this.$emit(
                    'input',
                    this.multiple ? value.map(item => item.id) : value.id
                )
            },
        },
    },
    watch: {
        exclude() {
            if (this.exclude?.length) {
                this.populateOptions()
            }
        },
    },
    mounted() {
        this.populateOptions()
    },
    methods: {
        populateOptions(searchQuery) {
            this.options = this.usersSortedByUsername.filter(
                user =>
                    !this.exclude.includes(user.id) &&
                    (!searchQuery ||
                        user.username.toLowerCase().includes(searchQuery) ||
                        `${user.first_name} ${user.last_name}`
                            .toLowerCase()
                            .includes(searchQuery) ||
                        !user.account_details?.name ||
                        user.account_details.name
                            .toLowerCase()
                            .includes(searchQuery))
            )
        },
        handleSearchChange(query) {
            this.populateOptions(query.trim().toLowerCase())
        },
    },
}
</script>

<i18n>
{
    "en": {
        "noOptions": "No users available",
        "noResult": "No users were found"
    },
    "de": {
        "noOptions": "Keine Benutzer verfügbar",
        "noResult": "Kein Benutzer gefunden"
    },
    "it": {
        "noOptions": "Nessun utente disponibile",
        "noResult": "Nessun utente è stato trovato"
    }
}
</i18n>

<style lang="scss" scoped>
.option {
    display: flex;
    justify-content: space-between;

    span {
        &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:last-child {
            margin-left: 1em;
            font-weight: 700;
            opacity: 0.5;
            white-space: nowrap;
        }
    }

    strong {
        margin-right: 0.5em;
    }
}
</style>
