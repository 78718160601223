// initial state
const state = {
    map: {
        tileLayerURL: process.env.VUE_APP_TILE_LAYER_URL,
    },
    apiUrl: process.env.VUE_APP_API_URL,
    jwtApiUrl: process.env.VUE_APP_JWT_API_URL,
    limitRecordPerRequest: process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
