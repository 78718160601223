// initial state
const state = {
    initLocationPolygon: [],
    locationPolygon: [],
    locationPolygonDrawing: false,
    resetLocationPolygon: false,
}

// getters
const getters = {
    getInitLocationPolygon(state) {
        return state.initLocationPolygon
    },
    getResetLocationPolygon(state) {
        return state.resetLocationPolygon
    },
    getLocationPolygon(state) {
        return state.locationPolygon
    },
    getLocationPolygonDrawing(state) {
        return state.locationPolygonDrawing
    },
}

// actions
const actions = {}

// mutations
const mutations = {
    initLocationPolygon(s, geofence) {
        s.initLocationPolygon = geofence
    },

    setLocationPolygonInitialized(s) {
        s.initLocationPolygon = null
    },

    setLocationPolygonDrawing(s, isDrawing) {
        s.locationPolygonDrawing = isDrawing
    },

    setLocationPolygon(s, locationPolygon) {
        s.locationPolygon = locationPolygon
    },

    resetLocationPolygon(s) {
        s.resetLocationPolygon = true
    },

    setResetLocationPolygonDone(s) {
        s.resetLocationPolygon = false
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
