const state = {
    users: [],
}

const getters = {
    usersSortedByUsername(state) {
        return state.users.sort((a, b) => a.username.localeCompare(b.username))
    },
}

export default {
    namespaced: true,
    state,
    getters,
}
