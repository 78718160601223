const state = {
    activeTrackerOnMap: null,
    activeTrackerTrace: [],
    assetTypes: [],
    filteredTrackerIds: null,
    filterParams: null,
    shouldFollowActiveTrackerUpdates: true,
    history: {
        selectedHistoryLocationID: null,
        data: null,
    },
    shouldAdjustMapOnFilterChange: false,
    trackers: [],
}

// getters
const getters = {
    lostAssets(state) {
        return state.trackers.filter(tracker => {
            return (
                tracker.last_gps_measurement &&
                tracker.last_gps_measurement.is_outside
            )
        })
    },
    lostAssetsCount(state, getters) {
        return getters.lostAssets.length
    },
    activeAssets(state) {
        return state.trackers.filter(({ active }) => active)
    },
    activeAssetsCount(state, getters) {
        return getters.activeAssets.length
    },
    inactiveAssets(state) {
        return state.trackers.filter(({ active }) => !active)
    },
    inactiveAssetsCount(state, getters) {
        return getters.inactiveAssets.length
    },
    lowBatteryAssets(state) {
        return state.trackers.filter(tracker => {
            return tracker.tracker_status && tracker.tracker_status.battery_low
        })
    },
    lowBatteryAssetsCount(state, getters) {
        return getters.lowBatteryAssets.length
    },
    alertAssets(state) {
        return state.trackers.filter(tracker => {
            return tracker.asset_details.alert
        })
    },
    alertAssetsCount(state, getters) {
        return getters.alertAssets.length
    },
    activeInLast6HoursAssets(state) {
        let now = new Date()
        return state.trackers.filter(
            ({ last_message_timestamp }) =>
                now - new Date(last_message_timestamp) < 21600000
        )
    },
    activeInLast6HoursCount(state, getters) {
        return getters.activeInLast6HoursAssets.length
    },
    assetsInLocation: state => locationId => {
        return state.trackers.filter(tracker => {
            return tracker.location == locationId
        })
    },
    assetsInLocationCount: (state, getters) => locationId => {
        return getters.assetsInLocation(locationId).length
    },
    assetsByType: state => assets => {
        const trackers = assets
            ? state.trackers.filter(tracker => assets.includes(tracker.asset))
            : state.trackers

        return trackers
            .map(tracker => tracker.asset_details.asset_type_identifier)
            .sort((a, b) => a.localeCompare(b))
            .reduce((acc, cur) => {
                acc[cur] = acc[cur] ? acc[cur] + 1 : 1
                return acc
            }, {})
    },
    fullBinAssets: state => (threshold = 0.8, height = 1.2) => {
        return state.trackers.filter(
            tracker =>
                tracker.asset_details.asset_type_type === 'bin' &&
                height - tracker.asset_details.sensor_data.distance.value >=
                    height * threshold
        )
    },
    assetHistoryData(state) {
        return state.history.data
    },
    assetTypesSortedByName(state) {
        return [...state.assetTypes].sort((a, b) =>
            a.name.localeCompare(b.name)
        )
    },
    selectedHistoryLocationID() {
        return state.history.selectedHistoryLocationID
    },
    assetTypeIcons(state) {
        return state.assetTypes.reduce((acc, cur) => {
            acc[cur.identifier] = cur.marker.url
            return acc
        }, {})
    },
}

// mutations
const mutations = {
    setActiveTrackerOnMap(state, data) {
        if (!data) {
            state.activeTrackerTrace = []
        } else if (data.id !== state.activeTrackerOnMap?.id) {
            const { latitude, longitude } = data.asset_details.position
            state.activeTrackerTrace = [[latitude, longitude]]
        }

        state.activeTrackerOnMap = data
    },
    setFilteredTrackerIds(state, data) {
        state.filteredTrackerIds = data
    },
    setFilterParams(state, data) {
        state.filterParams = data
    },
    setShouldAdjustMapOnFilterChange(state, data) {
        state.shouldAdjustMapOnFilterChange = data
    },
    setShouldFollowActiveTrackerUpdates(state, data) {
        state.shouldFollowActiveTrackerUpdates = data
    },
    updateAsset(state, data) {
        const trackers = [...state.trackers]
        const { asset_details: asset } = trackers.find(
            tracker => tracker.asset === data.id
        )

        if (!asset) {
            return
        }

        Object.keys(data).forEach(key => (asset[key] = data[key]))

        state.trackers = trackers
    },
    updateTracker(state, data) {
        const tracker = state.trackers.find(tracker => tracker.id === data.id)

        if (!tracker) {
            return
        }

        Object.keys(data).forEach(key => (tracker[key] = data[key]))
    },
    updateTrackerLastContact(state, { id, time, alert }) {
        const tracker = state.trackers.find(tracker => tracker.id === id)

        if (!tracker) {
            return
        }

        tracker.asset_details.alert = alert
        tracker.last_message_timestamp = time
    },
    updateTrackerSensorData(state, { id, values, time }) {
        const tracker = state.trackers.find(tracker => tracker.id === id)

        if (!tracker) {
            return
        }

        Object.entries(values).forEach(([key, value]) => {
            tracker.asset_details.sensor_data[key] = {
                value,
                last_update: time,
            }
        })

        if (values.battery) {
            const threshold = this.getters['authentication/batteryThreshold']

            tracker.asset_details.sensor_data.battery.low =
                Math.floor(values.battery) <= threshold

            tracker.tracker_status = {
                ...tracker.tracker_status,
                battery: Math.floor(values.battery),
                battery_low: Math.floor(values.battery) <= threshold,
                last_update: time,
            }
        }
    },
    updateTrackerPosition(state, { id, lat, lng, isOutside, time }) {
        const tracker = state.trackers.find(tracker => tracker.id === id)

        if (!tracker) {
            return
        }

        tracker.asset_details.position.latitude = lat
        tracker.asset_details.position.longitude = lng

        tracker.last_gps_measurement = {
            ...tracker.last_gps_measurement,
            lat,
            lng,
            is_outside: isOutside,
            timestamp: time,
        }

        if (state.activeTrackerOnMap?.id === id) {
            state.activeTrackerTrace = [[lat, lng], ...state.activeTrackerTrace]
        }
    },
    addHistoryData(state, data) {
        if (!state.history.data) {
            state.history.data = []
        }

        state.history.data.push(...data)
    },
    resetHistoryData(state) {
        state.history.data = null
    },
    setSelectedHistoryLocationID(state, selectedHistoryLocationId) {
        state.history.selectedHistoryLocationID = selectedHistoryLocationId
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
