import { render, staticRenderFns } from "./WhitelabledLogo.vue?vue&type=template&id=2103b9ba&scoped=true&"
import script from "./WhitelabledLogo.vue?vue&type=script&lang=js&"
export * from "./WhitelabledLogo.vue?vue&type=script&lang=js&"
import style0 from "./WhitelabledLogo.vue?vue&type=style&index=0&id=2103b9ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2103b9ba",
  null
  
)

export default component.exports